export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    customRender: (_, __, index) => index + 1,
  },
  {
    title: "物料名称",
    dataIndex: "name",
  },
  {
    title: "物料编号",
    dataIndex: "number",
  },
  {
    title: "物料条码",
    dataIndex: "barcode",
  },
  {
    title: "物料分类",
    dataIndex: "category_name",
  },
  {
    title: "物料规格",
    dataIndex: "spec",
  },
  {
    title: "物料单位",
    dataIndex: "unit_name",
  },
  {
    title: "库存数量",
    dataIndex: "inventory_quantity",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: 120,
    scopedSlots: { customRender: "action" },
  },
];
